var loader = {
	init: function() {
		  

		function loadComplete() { 
			var preloaderOutTl = new TimelineMax(); 
			preloaderOutTl
			.to('.progress', 0.3, {y: 100, autoAlpha: 0, ease:Back.easeIn})
			.to('.txt-perc', 0.3, {y: 100, autoAlpha: 0, ease:Back.easeIn}, 0.1)
			.set('body', {className: '-=is-loading'})
			.set('body', {className: '+=is-loaded'})
			.to('#preloader', 0.7, {autoAlpha: 0, ease:Power4.easeInOut})
			.set('#preloader', {className: '+=is-hidden'})
			.staggerTo('.item', .6, {autoAlpha: 1, ease:Power4.easeInOut}, .1)
			return preloaderOutTl;
		} 

		loadComplete();
	}

}