var navigation = {
	init: function() {
 
		//Burger menu animation with greensock / gsap
			var menu = $(".menu-icon"),
			    menuitem1 = "#js-menu",
			    menuitem2 = ".el-bg"

			//instantiate  timeline
			var tl = gsap.timeline({
				paused:true,
				ease: 'power2.out'
			}); 
 

			//collapse menu
			tl.to(menuitem1, {duration:0.15, autoAlpha:1}, 0)
			   .to(menuitem2, {duration:1.15, stagger: 0.4, x: '0%'}, 0)


			//expand menu
			  //.to(menuitem1, {duration, left: 20}, 1)
			  //.to(menuitem3, {duration, right: 20}, 1); 


			// play timeline on click, reverse animation if active
		menu.click(function() {
			$(this).toggleClass('active');
			$('body').toggleClass('is-menu'); 
			$('#js-menu').toggleClass('is-ready'); 

			if ( $(this).hasClass("active") ) {
				tl.play(); 
			}  
			else {
				tl.reverse(); 
			}
		});
		$('.menu-el').on('click',function(e){
			tl.reverse(); 
			$('.menu-icon').toggleClass('active');
			$('body').toggleClass('is-menu'); 
			$('#js-menu').toggleClass('is-ready'); 
		})

		
 

  
 		var controller = new ScrollMagic.Controller();

		var intro = $('.btnintro');
		intro.click( function(e) {
			e.preventDefault(); 
			TweenLite.to(window, 1, {scrollTo:{y:$("body").offset().top+0}, ease:Expo.easeOut})
		});

 
	 

		controller.scrollTo(function(newpos) {
	      TweenMax.to(window, 1, {
	        scrollTo: {
	          x: newpos
	        },
	        ease: Power1.easeInOut
	      });
	    });

	    //  bind scroll to anchor links
	    $(document).on("click", "a[href^='#']", function(e) {
	      var id = $(this).attr("href");
	      if ($(id).length > 0) {
	        e.preventDefault();

	        // trigger scroll
	        controller.scrollTo(id);

	        // if supported by the browser we can even update the URL.
	        if (window.history && window.history.pushState) {
	          history.pushState("", document.title, id);
	        }
	      }
	    });


	}
}