var scrollMagic = {
	init: function() {
    var controller = new ScrollMagic.Controller();

    $(window).on("scroll touchmove", function () {
      var fixHeaderHeight = $('#intro').outerHeight() * 1.5

      if ($(window).scrollTop() >= fixHeaderHeight) {
           $('header.header').addClass('fixed'); 
        }
        else {
           $('header.header').removeClass('fixed'); 
        }
    });
  
    $(".hero-wrapper .item").each(function(){
      $(this).removeClass('visible');
      new ScrollMagic.Scene({
        triggerElement: this,
        triggerHook: .3
      })
      .on("enter", function(ev){$(ev.target.triggerElement()).addClass('visible');})
      .on("leave", function(ev){$(ev.target.triggerElement()).removeClass('visible');})
      .addTo(controller);
    }); 



	}
}
