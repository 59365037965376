var generic = {
  init: function() {



  var module = {
  init: function () {
    this.slideMenuNavigation();
    this.scrollToTop(); 
    this.parallax();  
  }, 
 
 
  
  scrollToTop: function (){

    var body = $("body,html");
    var scrollToTop = $("a.scrolltotop");

    $(window).scroll(function () {
        if ($(this).scrollTop() > 100) {
         scrollToTop.fadeIn();
        } else {
         scrollToTop.fadeOut();
        }
      });
      

    scrollToTop.click(function () {
      body.animate({scrollTop: 0 }, 800);
      return false;
    });
  },

  parallax: function (){
    $(window).bind('scroll', function (e) {
      parallaxScroll();
    });

    function parallaxScroll() {
      var scrolled = $(window).scrollTop();
        $('.theme > img').css('top', (0 - (scrolled * .2)) + 'px');
      }
  },

  slideMenuNavigation: function() {

    var $window = $(window);
    var $pane = $('.st-pusher');

    function checkHeight() {
      var height = parseInt($(".st-pusher").height());
      var windowsize = $window.height();
      $(".st-pusher").height($(".navigation").height());  
    }
    checkHeight();
    $(window).resize(checkHeight);
      
    
    $('li.contact a').click(function(){
      var body = $('html,body');
      body.animate({
        scrollTop:$('#contact-us').offset().top - 136 }, 1000);
      return false;
    });
  
     
          
    $('#sidebar-toggle').on('click', function() {

      $(this).toggleClass('active');
      var toggle_el = $(this).data('toggle');
      $(toggle_el).toggleClass('st-menu-open');
      $('#st-container').toggleClass('st-menu-open');

      if ($('.open-sidebar').is(':visible')) {
        $('.close-menu').bind('click', function () {
          $('#st-container').removeClass('open-sidebar');
        });
 
      }

    });

      $('.subnav .TheSchool').click(function() {
        $('.active').removeClass('active');
        $('.TheSchool').addClass('active');
        $('html, body').animate({scrollTop: $('#TheSchool').offset().top -120 }, 'slow');
        return false;
      });
      
      $('.subnav .Services').click(function( ) {
        $('.active').removeClass('active');
        $('.Services').addClass('active');
        $('html, body').animate({scrollTop: $('#Services').offset().top -120 }, 'slow');
        return false;
      });

      $('.subnav .TheCurriculum').click(function() {
        $('.active').removeClass('active');
        $('.TheCurriculum').addClass('active');
        $('html, body').animate({scrollTop: $('#TheCurriculum').offset().top -120 }, 'slow');
        return false;
      });

      $('.subnav .OurAims').click(function() {
        $('.active').removeClass('active');
        $('.OurAims').addClass('active');
        $('html, body').animate({scrollTop: $('#OurAims').offset().top -120 }, 'slow');
        return false;
      });

      $('.subnav .Fees').click(function() {
        $('.active').removeClass('active');
        $('.Fees').addClass('active');
        $('html, body').animate({scrollTop: $('#Fees').offset().top -120 }, 'slow');
        return false;
      });

      $('.subnav .Admissions').click(function() {
        $('.active').removeClass('active');
        $('.Admissions').addClass('active');
        $('html, body').animate({scrollTop: $('#Admissions').offset().top -120 }, 'slow');
        return false;
      });
  }
   

};

$(document).ready(function () {
  module.init();
  $('body').addClass('loaded');
});




  }
}