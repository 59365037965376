var gallery = {
	init: function() {

    var $overlay = $('<div id="overlay"></div>');
    var $image = $("<img>");

    //An image to overlay
    $overlay.append($image);

    //Add overlay
    $("body").append($overlay);

      //click the image and a scaled version of the full size image will appear
      $("#photo-gallery a").click( function(event) {
        event.preventDefault();
        var imageLocation = $(this).attr("href");

        //update overlay with the image linked in the link
        $image.attr("src", imageLocation);

        //show the overlay
        //$overlay.show();
        TweenLite.to($overlay, .6, {ease: Power4.easeOut, autoAlpha:1,display:'block'});

      } );

      $("#overlay").click(function() {
        	TweenLite.to("#overlay", .6, {ease: Power4.easeOut, autoAlpha:0,display:'none'});
      });

    function instagram (){ 
	    var galleryFeed = new Instafeed({
	      get: "user", 
	      userId: 9210409046,
	      clientId: "fc74018c97154128b17189eed8aa8613",
	      accessToken: "9210409046.1677ed0.8fa4b0c101a345939bf5bcaf8979234d",
	      resolution: "standard_resolution",
	      useHttp: "true",
	      limit: 6,
	      template: '<li><a href="{{image}}" target="_blank"><div class="img-featured-container"><div class="img-backdrop"></div><div class="description-container"><p class="caption">{{caption}}</p><span class="likes"><i class="icon ion-heart"></i> {{likes}}</span><span class="comments"><i class="icon ion-chatbubble"></i> {{comments}}</span></div><img src="{{image}}" class="img-responsive"></div></a></li>',
	      target: "instafeed-gallery-feed",
	      after: function() {
	        // disable button if no more results to load
	        if (!this.hasNext()) {
	          btnInstafeedLoad.setAttribute('disabled', 'disabled');
	        }
	      },
	    });
	    galleryFeed.run();

	    var btnInstafeedLoad = document.getElementById("btn-instafeed-load");
	    btnInstafeedLoad.addEventListener("click", function() {
	      galleryFeed.next()
	    });
	  }
	  instagram();

 

	}
}










